.form-wrapper {
    position: relative;
}

.form-wrapper .buying-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.buying-text {
    color: #c5ac79;
    font-weight: 600;
    /* font-family: "Work Sans"; */
}

.tip-message {
    text-align: left;
    padding-bottom: 20px;
    color: #FF494A;
    font-weight: 400;
    opacity: 0.8;
    font-size: clamp(14px, 1.1rem, 16px);
    white-space: pre-line;
    line-height: 22px
}

.normal-message {
    padding-bottom: 20px;
    color: #FFF;
    font-weight: 400;
    opacity: 0.8;
    font-size: clamp(14px, 1.1rem, 16px);
    white-space: pre-line;
}

.wallect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.form-wrapper {
    border-radius: 24px;
    border: 1px solid #30323a;
    background: #181a20;
    box-shadow: 2px 3px 4px 0 rgba(0, 0, 0, 0.25) inset;
    padding: 15px 20px 24px;
}

.address {
    padding: 10px 0 15px 10px;
}

.logo {
    width: 136px;
    height: 48px;
    float: right;
    margin-top: 10px;
}

.grid-content {
    /* margin: 5px 0px 0px; */
    color: #c5ac79;
    text-align: left;
    font-weight: 600;
    /* font-family: sans-serif; */
    font-size: clamp(40px, 4vw, 57px);
    /* letter-spacing: 2.32px; */
}

.sold-out-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin-top: 10px;
}

.sold-out-tip {
    /* margin: 5px 0px 0px; */
    color: #c5ac79;
    text-align: center;
    /* font-weight: 600; */
    /* font-family: sans-serif; */
    font-size: clamp(28px, 1vw, 36px);
    /* letter-spacing: 2.32px; */
}


.current-col {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
    &:nth-child(1){
        text-align: left;
    }
}

.current-col>p:nth-child(2) {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.current-col:nth-child(2) {
    text-align: right;
}

.tips {
    font-size: 16px;
    font-weight: 600;
    margin-top: 12px;
}

.approve-tip {
    margin-top: 10px;
}

.pay-tips {
    font-size: 16px;
    font-weight: 600;
}

.logtips {
    font-size: 16px;
    font-weight: 600;
}

.tips>.max-value {
    color: #c5ac79;
    text-align: right;
    float: right;

}

.max-value {
    /* font-size: 13px; */
    border: 1px solid #efd8aa;
    text-align: center;
    border-radius: 4px;
    padding: 2px;
    font-size: 13px;
    font-weight: 500;
    /* background-color: #c5ac79; */
}

.max-value:hover {
    background: linear-gradient(0deg, #2B2E39, #2B2E39),
    linear-gradient(0deg, #E3C076, #E3C076);
}

.time-btn {
    border-radius: 8px;
    border: 1px solid #434755;
    background: #30323a;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 30px;
    height: 30px;
    width: 100%;
    display: block;
    cursor: pointer;
}

.eth-btn {
    width: 100%;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    border: 1px solid #434755;
    background: #30323a;
    color: #fff;
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
}

.on {
    border-radius: 8px;
    border: 1px solid #efd8aa;
    background: rgba(197, 172, 121, 0.3);
    color: #c5ac79;
}

.connect-btn , .buy-and-stake-btn{
    width: 100%;
    height: 52px;
    border-radius: 8px;
    border: 1px solid #efd8aa !important;
    background: #c5ac79 !important;
    color: #181a20;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.connect-btn:hover {
    background-color: #FFD581;
}

.buy-and-stake-btn:hover {
    background-color: #FFD581 !important;
    color: #181a20;
    border: 1px solid #efd8aa;
}

.gasTip {
    text-align: left;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    padding-bottom: 15px;
}

.gasTipLink {
    margin-left: 8px;
    color: #c5ac79;
}

.buy-and-stake-btn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}


.stake-buy-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.stake-buy-btn {
    color: rgba(255, 255, 255, 0.6) !important;
    font-size: 14px;
    margin-top: 15px;
    background-color: transparent;
    border-color: transparent;
    line-height: 30px;
    text-align: center;
    text-decoration: underline;
    text-underline-offset: 4px;

}

.stake-buy-btn:disabled {
    cursor: not-allowed;

}

.stake-buy-btn:hover {
    text-decoration: unset;
    text-underline-offset: unset;
}

.other-buy {
    height: 52px;
    color: #c5ac79;
    font-size: 20px;
    text-align: center;
    line-height: 52px;
    font-weight: bold;
    border-radius: 8px;
    border: 1px solid #efd8aa;
    background: #181a20;
    cursor: pointer;
}

.gray-tips {
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    padding: 16px 0 8px;
    text-decoration: underline;
    text-underline-offset: 4px;
}

.f-ipt {
    margin-top: 10px;
    margin-bottom: 20px;
}

.icon {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    vertical-align: -5px;
}

.lououtContainer {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.logout:hover {
    background-color: #918a7d;
}

.logout {
    width: 25px;
    height: 25px;
}

.ant-progress-inner{
    background-color: #30323a;
}

@media screen and (max-width: 900px) {
    .form-wrapper{
        // .icon{
        //     width: 15px;
        //     height: 15px;
        // }

        .tips {
            font-size: 16px;
            font-weight: 600;
            margin-top: 10px;
        }

        .f-ipt {
            margin-top: 5px !important;
            margin-bottom: 20px;
        }

        .form-wrapper {
            padding: 15px 20px 24px;
        }

        .logo {
            display: none;
        }
    }
}

.ant-input-affix-wrapper {
    border-radius: 8px;
    border: 1px solid #434755;
    background: #30323a;
    color: #ffffff;
    box-shadow: none;
    position: static;
    height: 52px;
    font-size: 20px;
}
.ant-input{
    background-color: #30323a;
    color: #fff;
}
.ant-input-clear-icon{
    color: #fff;
}

